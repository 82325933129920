import React from 'react';

import GatsbyImage from 'common/GatsbyImage';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { IProductClassProps } from './model';

import './ProductClass.scss';

const ProductClass: React.FC<IProductClassProps> = ({ icon, label, bgColor }) => (
  <div className="product-class">
    <div className={`product-class__icon ${bgColor.label}--bg`}>
      <GatsbyImage image={icon} alt={label} objectFit="contain" />
    </div>
    <DangerouslySetInnerHtml className="product-class__label" html={label} />
  </div>
);

export default ProductClass;
